var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "", "pa-0": "" } },
    [
      _c("header", { staticClass: "app-welcome-message" }, [
        !_setup.isAddingMasterMode && !_setup.isAddingDetailMode
          ? _c("h2", [_vm._v("Details van partij")])
          : _setup.isAddingMasterMode
          ? _c("h2", [_vm._v("Toevoegen van partij")])
          : _setup.isAddingDetailMode
          ? _c("h2", [_vm._v("Toevoegen van partijversie")])
          : _vm._e(),
      ]),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "v-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "v-row",
                    { attrs: { align: "center" } },
                    [
                      _setup.hasDetails
                        ? _c("v-col", { attrs: { cols: "auto" } }, [
                            _vm._v(" Tijdlijn: "),
                          ])
                        : _vm._e(),
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-btn-toggle",
                            {
                              attrs: {
                                group: "",
                                mandatory: "",
                                color: "blue accent-3",
                                elevation: "2",
                              },
                              model: {
                                value: _setup.selectedTabIndex,
                                callback: function ($$v) {
                                  _setup.selectedTabIndex = $$v
                                },
                                expression: "selectedTabIndex",
                              },
                            },
                            _vm._l(_setup.detailTabs(), function (item, index) {
                              return _c(
                                "v-btn",
                                {
                                  key: index,
                                  staticClass: "v-application elevation-2",
                                  attrs: {
                                    value: index,
                                    disabled: !_setup.isReadOnly,
                                  },
                                },
                                [_vm._v(" " + _vm._s(item) + " ")]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                _setup.ValidationObserver,
                { ref: "obs" },
                [
                  _c(
                    "v-form",
                    [
                      _c(
                        "masonry",
                        {
                          attrs: { cols: { default: 2, 1264: 1 }, gutter: 12 },
                        },
                        [
                          _c(
                            "v-container",
                            {
                              staticClass: "boxed",
                              class: { noBorders: !_setup.isEditTLMode },
                              attrs: { fluid: "" },
                            },
                            [
                              _c("header", [_vm._v("Geldigheid")]),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        ref: "validFrom",
                                        attrs: {
                                          name: "Geldig vanaf",
                                          rules: "date_between|required",
                                          mode: _setup.checkDateValid,
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c(_setup.DateInput, {
                                                  class: {
                                                    noBorders:
                                                      !_setup.allowEditTL,
                                                  },
                                                  attrs: {
                                                    "persistent-placeholder":
                                                      "",
                                                    label: "Geldig vanaf",
                                                    errors: errors,
                                                    outlined:
                                                      _setup.allowEditTL,
                                                    readonly:
                                                      !_setup.allowEditTL,
                                                    rules: "required",
                                                  },
                                                  model: {
                                                    value:
                                                      _setup.selectedData
                                                        .valFrom,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _setup.selectedData,
                                                        "valFrom",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "selectedData.valFrom",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Geldig tot",
                                          rules: `date_between|date_after:${_setup.selectedData.valFrom}`,
                                          mode: _setup.checkDateValid,
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c(_setup.DateInput, {
                                                  class: {
                                                    noBorders:
                                                      !_setup.allowEditTL,
                                                  },
                                                  attrs: {
                                                    "persistent-placeholder":
                                                      "",
                                                    label: "Geldig tot",
                                                    errors: errors,
                                                    outlined:
                                                      _setup.allowEditTL,
                                                    readonly:
                                                      !_setup.allowEditTL,
                                                  },
                                                  model: {
                                                    value:
                                                      _setup.selectedData
                                                        .valTill,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _setup.selectedData,
                                                        "valTill",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "selectedData.valTill",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-container",
                            {
                              staticClass: "boxed",
                              class: {
                                noBorders:
                                  !_setup.isAddingMasterMode &&
                                  !_setup.isAddingDetailMode,
                              },
                            },
                            [
                              _c("header", [_vm._v("Aanpassing")]),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Aanpassings omschrijving",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c("v-text-field", {
                                                  class: {
                                                    noBorders:
                                                      !_setup.allowEdit &&
                                                      !_setup.allowEditTL,
                                                  },
                                                  attrs: {
                                                    "hide-details": "auto",
                                                    "persistent-placeholder":
                                                      "",
                                                    label: "Omschrijving",
                                                    "error-messages": errors,
                                                    outlined:
                                                      _setup.allowEdit ||
                                                      _setup.allowEditTL,
                                                    readonly:
                                                      !_setup.allowEdit &&
                                                      !_setup.allowEditTL,
                                                  },
                                                  model: {
                                                    value:
                                                      _setup.selectedData
                                                        .modDescr,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _setup.selectedData,
                                                        "modDescr",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "selectedData.modDescr",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              !_setup.isAddingMode
                                ? _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c("v-text-field", {
                                            staticClass: "noBorders",
                                            attrs: {
                                              "hide-details": "auto",
                                              label: "Door",
                                              "persistent-placeholder": "",
                                              readonly: "",
                                            },
                                            model: {
                                              value: _setup.selectedData.modBy,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _setup.selectedData,
                                                  "modBy",
                                                  $$v
                                                )
                                              },
                                              expression: "selectedData.modBy",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              !_setup.isAddingMode
                                ? _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c("v-text-field", {
                                            staticClass: "noBorders",
                                            attrs: {
                                              "hide-details": "auto",
                                              label: "Datum",
                                              "persistent-placeholder": "",
                                              readonly: "",
                                            },
                                            model: {
                                              value: _setup.modDateFormatted,
                                              callback: function ($$v) {
                                                _setup.modDateFormatted = $$v
                                              },
                                              expression: "modDateFormatted",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "masonry",
                        {
                          attrs: {
                            cols: { default: 3, 768: 2, 1264: 1 },
                            gutter: 12,
                          },
                        },
                        [
                          _c(
                            "v-container",
                            { staticClass: "boxed" },
                            [
                              _c("header", [_vm._v("Logo")]),
                              _c(
                                "v-row",
                                [
                                  _c("v-col", [
                                    _c("img", {
                                      staticStyle: {
                                        "max-width": "200px",
                                        "max-height": "200px",
                                      },
                                      attrs: { src: _setup.logoName },
                                    }),
                                  ]),
                                  _c(
                                    "v-col",
                                    [
                                      _c(
                                        "v-container",
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                [
                                                  _c(_setup.UpdatePartyModal, {
                                                    attrs: {
                                                      partyReference:
                                                        _setup.partyRef,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass: "secondary",
                                                      attrs: {
                                                        dark: "",
                                                        title: "Verwijderen",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _setup.onDeleteLogo()
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            dark: "",
                                                            left: "",
                                                          },
                                                        },
                                                        [_vm._v("mdi-delete")]
                                                      ),
                                                      _vm._v(" Verwijderen "),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-container",
                            { staticClass: "boxed" },
                            [
                              _c("header", [_vm._v("Energieprijzen")]),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Prijs elektriciteit",
                                          rules:
                                            "decimal:4|between:0,999999999.9999",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c(
                                                  "v-text-field",
                                                  {
                                                    class: {
                                                      noBorders:
                                                        !_setup.allowEdit,
                                                    },
                                                    attrs: {
                                                      type: "number",
                                                      step: "0.01",
                                                      "hide-details": "auto",
                                                      label:
                                                        "Prijs elektriciteit",
                                                      "persistent-placeholder":
                                                        "",
                                                      value:
                                                        _setup.selectedData
                                                          .priceElec,
                                                      "error-messages": errors,
                                                      outlined:
                                                        _setup.allowEdit,
                                                      readonly:
                                                        !_setup.allowEdit,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        _setup.selectedData.priceElec =
                                                          $event !== ""
                                                            ? $event
                                                            : undefined
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "append" },
                                                      [_vm._v("€/kWh")]
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Prijs gas",
                                          rules:
                                            "decimal:4|between:0,999999999.9999",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c(
                                                  "v-text-field",
                                                  {
                                                    class: {
                                                      noBorders:
                                                        !_setup.allowEdit,
                                                    },
                                                    attrs: {
                                                      type: "number",
                                                      step: "0.01",
                                                      "hide-details": "auto",
                                                      label: "Prijs gas",
                                                      "persistent-placeholder":
                                                        "",
                                                      value:
                                                        _setup.selectedData
                                                          .priceGas,
                                                      "error-messages": errors,
                                                      outlined:
                                                        _setup.allowEdit,
                                                      readonly:
                                                        !_setup.allowEdit,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        _setup.selectedData.priceGas =
                                                          $event !== ""
                                                            ? $event
                                                            : undefined
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "append" },
                                                      [_vm._v("€/m³")]
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Prijs biomassa",
                                          rules:
                                            "decimal:4|between:0,999999999.9999",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c(
                                                  "v-text-field",
                                                  {
                                                    class: {
                                                      noBorders:
                                                        !_setup.allowEdit,
                                                    },
                                                    attrs: {
                                                      type: "number",
                                                      step: "0.01",
                                                      "hide-details": "auto",
                                                      label: "Prijs biomassa",
                                                      "persistent-placeholder":
                                                        "",
                                                      value:
                                                        _setup.selectedData
                                                          .priceBio,
                                                      "error-messages": errors,
                                                      outlined:
                                                        _setup.allowEdit,
                                                      readonly:
                                                        !_setup.allowEdit,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        _setup.selectedData.priceBio =
                                                          $event !== ""
                                                            ? $event
                                                            : undefined
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "append" },
                                                      [_vm._v("€/m³")]
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Prijs warmte",
                                          rules:
                                            "decimal:4|between:0,999999999.9999",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c(
                                                  "v-text-field",
                                                  {
                                                    class: {
                                                      noBorders:
                                                        !_setup.allowEdit,
                                                    },
                                                    attrs: {
                                                      type: "number",
                                                      step: "0.01",
                                                      "hide-details": "auto",
                                                      label: "Prijs warmte",
                                                      "persistent-placeholder":
                                                        "",
                                                      value:
                                                        _setup.selectedData
                                                          .priceHeat,
                                                      "error-messages": errors,
                                                      outlined:
                                                        _setup.allowEdit,
                                                      readonly:
                                                        !_setup.allowEdit,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        _setup.selectedData.priceHeat =
                                                          $event !== ""
                                                            ? $event
                                                            : undefined
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "append" },
                                                      [_vm._v("€/GJ")]
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Prijs koude",
                                          rules:
                                            "decimal:4|between:0,999999999.9999",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c(
                                                  "v-text-field",
                                                  {
                                                    class: {
                                                      noBorders:
                                                        !_setup.allowEdit,
                                                    },
                                                    attrs: {
                                                      type: "number",
                                                      step: "0.01",
                                                      "hide-details": "auto",
                                                      label: "Prijs koude",
                                                      "persistent-placeholder":
                                                        "",
                                                      value:
                                                        _setup.selectedData
                                                          .priceCold,
                                                      "error-messages": errors,
                                                      outlined:
                                                        _setup.allowEdit,
                                                      readonly:
                                                        !_setup.allowEdit,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        _setup.selectedData.priceCold =
                                                          $event !== ""
                                                            ? $event
                                                            : undefined
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "append" },
                                                      [_vm._v("€/GJ")]
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-container",
                            { staticClass: "boxed" },
                            [
                              _c("header", [_vm._v("Verduurzamingsradar")]),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Score strategie",
                                          rules: "decimal:4|between:0,7",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c("v-text-field", {
                                                  class: {
                                                    noBorders:
                                                      !_setup.allowEdit,
                                                  },
                                                  attrs: {
                                                    type: "number",
                                                    step: "0.1",
                                                    "hide-details": "auto",
                                                    label: "Score strategie",
                                                    "persistent-placeholder":
                                                      "",
                                                    value:
                                                      _setup.selectedData
                                                        .radarStrategy,
                                                    "error-messages": errors,
                                                    outlined: _setup.allowEdit,
                                                    readonly: !_setup.allowEdit,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      _setup.selectedData.radarStrategy =
                                                        $event !== ""
                                                          ? $event
                                                          : undefined
                                                    },
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Score inzicht",
                                          rules: "decimal:4|between:0,7",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c("v-text-field", {
                                                  class: {
                                                    noBorders:
                                                      !_setup.allowEdit,
                                                  },
                                                  attrs: {
                                                    type: "number",
                                                    step: "0.1",
                                                    "hide-details": "auto",
                                                    label: "Score inzicht",
                                                    "persistent-placeholder":
                                                      "",
                                                    value:
                                                      _setup.selectedData
                                                        .radarInsight,
                                                    "error-messages": errors,
                                                    outlined: _setup.allowEdit,
                                                    readonly: !_setup.allowEdit,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      _setup.selectedData.radarInsight =
                                                        $event !== ""
                                                          ? $event
                                                          : undefined
                                                    },
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Score gedeelde waarden",
                                          rules: "decimal:4|between:0,7",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c("v-text-field", {
                                                  class: {
                                                    noBorders:
                                                      !_setup.allowEdit,
                                                  },
                                                  attrs: {
                                                    type: "number",
                                                    step: "0.1",
                                                    "hide-details": "auto",
                                                    label:
                                                      "Score gedeelde waarden",
                                                    "persistent-placeholder":
                                                      "",
                                                    value:
                                                      _setup.selectedData
                                                        .radarSharedValues,
                                                    "error-messages": errors,
                                                    outlined: _setup.allowEdit,
                                                    readonly: !_setup.allowEdit,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      _setup.selectedData.radarSharedValues =
                                                        $event !== ""
                                                          ? $event
                                                          : undefined
                                                    },
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Score status",
                                          rules: "decimal:4|between:0,7",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c("v-text-field", {
                                                  class: {
                                                    noBorders:
                                                      !_setup.allowEdit,
                                                  },
                                                  attrs: {
                                                    type: "number",
                                                    step: "0.1",
                                                    "hide-details": "auto",
                                                    label: "Score status",
                                                    "persistent-placeholder":
                                                      "",
                                                    value:
                                                      _setup.selectedData
                                                        .radarStatus,
                                                    "error-messages": errors,
                                                    outlined: _setup.allowEdit,
                                                    readonly: !_setup.allowEdit,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      _setup.selectedData.radarStatus =
                                                        $event !== ""
                                                          ? $event
                                                          : undefined
                                                    },
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Score slagkracht",
                                          rules: "decimal:4|between:0,7",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c("v-text-field", {
                                                  class: {
                                                    noBorders:
                                                      !_setup.allowEdit,
                                                  },
                                                  attrs: {
                                                    type: "number",
                                                    step: "0.1",
                                                    "hide-details": "auto",
                                                    label: "Score slagkracht",
                                                    "persistent-placeholder":
                                                      "",
                                                    value:
                                                      _setup.selectedData
                                                        .radarStrikingPower,
                                                    "error-messages": errors,
                                                    outlined: _setup.allowEdit,
                                                    readonly: !_setup.allowEdit,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      _setup.selectedData.radarStrikingPower =
                                                        $event !== ""
                                                          ? $event
                                                          : undefined
                                                    },
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-container",
                            { staticClass: "boxed" },
                            [
                              _c("header", [_vm._v("Algemene gegevens")]),
                              _setup.selectedData.reference != 0
                                ? _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c("v-text-field", {
                                            staticClass: "noBorders",
                                            attrs: {
                                              "hide-details": "auto",
                                              label: "Referentie",
                                              readonly: "",
                                            },
                                            model: {
                                              value:
                                                _setup.selectedData.reference,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _setup.selectedData,
                                                  "reference",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "selectedData.reference",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _setup.selectedData.reference != 0
                                ? _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c("v-select", {
                                            class: {
                                              noBorders: !_setup.allowEdit,
                                            },
                                            attrs: {
                                              "hide-details": "auto",
                                              label: "Parent",
                                              "persistent-placeholder": "",
                                              items: _setup.parentParties,
                                              "item-text": "name",
                                              "item-value": "id",
                                              outlined: _setup.allowEdit,
                                              readonly: !_setup.allowEdit,
                                            },
                                            model: {
                                              value: _setup.selectedData.parId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _setup.selectedData,
                                                  "parId",
                                                  $$v
                                                )
                                              },
                                              expression: "selectedData.parId",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Naam",
                                          rules: "required|max:250",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors, valid }) {
                                              return [
                                                _c("v-text-field", {
                                                  class: {
                                                    noBorders:
                                                      !_setup.allowEdit,
                                                  },
                                                  attrs: {
                                                    "hide-details": "auto",
                                                    label: "Naam",
                                                    "persistent-placeholder":
                                                      "",
                                                    counter: 250,
                                                    "error-messages": errors,
                                                    success: valid,
                                                    outlined: _setup.allowEdit,
                                                    readonly: !_setup.allowEdit,
                                                  },
                                                  model: {
                                                    value:
                                                      _setup.selectedData.name,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _setup.selectedData,
                                                        "name",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "selectedData.name",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Omschrijving",
                                          rules: "required|max:1000",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors, valid }) {
                                              return [
                                                _c("v-text-field", {
                                                  class: {
                                                    noBorders:
                                                      !_setup.allowEdit,
                                                  },
                                                  attrs: {
                                                    "hide-details": "auto",
                                                    label: "Omschrijving",
                                                    "persistent-placeholder":
                                                      "",
                                                    counter: 1000,
                                                    "error-messages": errors,
                                                    success: valid,
                                                    outlined: _setup.allowEdit,
                                                    readonly: !_setup.allowEdit,
                                                  },
                                                  model: {
                                                    value:
                                                      _setup.selectedData.descr,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _setup.selectedData,
                                                        "descr",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "selectedData.descr",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-select", {
                                        class: { noBorders: !_setup.allowEdit },
                                        attrs: {
                                          "hide-details": "auto",
                                          label: "Type",
                                          "persistent-placeholder": "",
                                          items: _setup.partyTypes,
                                          "item-text": "descr",
                                          "item-value": "key",
                                          outlined: _setup.allowEdit,
                                          readonly: !_setup.allowEdit,
                                        },
                                        model: {
                                          value: _setup.selectedData.type,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _setup.selectedData,
                                              "type",
                                              $$v
                                            )
                                          },
                                          expression: "selectedData.type",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-container",
                            { staticClass: "boxed" },
                            [
                              _c("header", [_vm._v("Contacten")]),
                              _vm._l(_setup.contactList, function (item) {
                                return _c(
                                  "v-row",
                                  { key: item.keyId },
                                  [
                                    _c("v-col", { attrs: { cols: "4" } }, [
                                      _vm._v(_vm._s(item.partyRoleDescription)),
                                    ]),
                                    _c("v-col", [
                                      _vm._v(_vm._s(item.userDescription)),
                                    ]),
                                    item.valid == false
                                      ? _c("v-col", [_vm._v(" (niet actief) ")])
                                      : _vm._e(),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "auto" } },
                                      [
                                        _setup.allowEdit
                                          ? _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  icon: "",
                                                  small: "",
                                                  plain: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _setup.delContact(
                                                      item.keyId
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { small: "" } },
                                                  [_vm._v(" mdi-delete ")]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }),
                              _setup.allowEdit
                                ? _c("v-divider", { staticClass: "my-3" })
                                : _vm._e(),
                              _setup.allowEdit
                                ? _c(_setup.ContactRoleSelection, {
                                    on: {
                                      "on-add-contact": _setup.onAddContact,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            2
                          ),
                          _c(
                            "v-container",
                            { staticClass: "boxed" },
                            [
                              _c("header", [_vm._v("Herkomst")]),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Bron",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c("v-select", {
                                                  class: {
                                                    noBorders:
                                                      !_setup.allowEdit,
                                                  },
                                                  attrs: {
                                                    "hide-details": "auto",
                                                    label: "Bron",
                                                    "persistent-placeholder":
                                                      "",
                                                    items: _setup.sourceTypes,
                                                    "item-text": "descr",
                                                    "item-value": "key",
                                                    "error-messages": errors,
                                                    outlined: _setup.allowEdit,
                                                    readonly: !_setup.allowEdit,
                                                  },
                                                  model: {
                                                    value:
                                                      _setup.selectedData
                                                        .sourceType,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _setup.selectedData,
                                                        "sourceType",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "selectedData.sourceType",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Source Reference",
                                          rules: "max:50",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c("v-text-field", {
                                                  class: {
                                                    noBorders:
                                                      !_setup.allowEdit,
                                                  },
                                                  attrs: {
                                                    "hide-details": "auto",
                                                    label: "Bron referentie",
                                                    "persistent-placeholder":
                                                      "",
                                                    counter: 50,
                                                    "error-messages": errors,
                                                    outlined: _setup.allowEdit,
                                                    readonly: !_setup.allowEdit,
                                                  },
                                                  model: {
                                                    value:
                                                      _setup.selectedData
                                                        .sourceReference,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _setup.selectedData,
                                                        "sourceReference",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "selectedData.sourceReference",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("details-buttons", {
                        attrs: {
                          addMode: _setup.isAddingMode,
                          editMode: !_setup.isReadOnly,
                          allowSave: !_setup.isSaving,
                        },
                        on: {
                          edit: _setup.setEditMode,
                          "edit-tl": _setup.setEditTLMode,
                          "on-cancel": _setup.onCancel,
                          "add-new-detail": _setup.addNewDetail,
                          "on-cancel-add": function ($event) {
                            return _setup.onCancelAdd()
                          },
                          "save-detail": _setup.saveDetail,
                          "save-new-detail": _setup.saveNewDetail,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }